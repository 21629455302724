export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_SERVICE = 'GET_SERVICE';
export const GET_FAQ = 'GET_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const UPDATE_STATUS_FAQ = 'UPDATE_STATUS_FAQ';
export const GET_VIDEOLIBRARY = 'GET_VIDEOLIBRARY';
export const DELETE_VIDEOLIBRARY = 'DELETE_VIDEOLIBRARY';
export const UPDATE_VIDEOLIBRARY = 'UPDATE_VIDEOLIBRARY';
export const UPDATE_STATUS_VIDEOLIBRARY = 'UPDATE_STATUS_VIDEOLIBRARY';
export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const UPDATE_QUESTIONNAIRE = 'UPDATE_QUESTIONNAIRE';
export const DELETE_QUESTIONNAIRE = 'DELETE_QUESTIONNAIRE';
export const GET_QUESTIONNAIRE_DETAILS = 'GET_QUESTIONNAIRE_DETAILS';
export const UPDATE_STATUS_QUESTIONNAIRE = 'UPDATE_STATUS_QUESTIONNAIRE';
export const GET_PATIENTS_INSURANCE = 'GET_PATIENTS_INSURANCE';
export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_ARTICLES = 'GET_ARTICLES';
export const DELETE_ARTICLES = 'DELETE_ARTICLES';
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES';
export const UPDATE_STATUS_ARTICLES = 'UPDATE_STATUS_ARTICLES';
export const GET_RARTICLES = 'GET_RARTICLES';
export const DELETE_RARTICLES = 'DELETE_RARTICLES';
export const UPDATE_RARTICLES = 'UPDATE_RARTICLES';
export const UPDATE_STATUS_RARTICLES = 'UPDATE_STATUS_RARTICLES';
export const UPDATE_SCHEDULED_DAYS = 'UPDATE_SCHEDULED_DAYS';
export const UPDATE_SCHEDULED_TYPE = 'UPDATE_SCHEDULED_TYPE';
export const GET_PATIENTS_QUESTIONNAIRE = 'GET_PATIENTS_QUESTIONNAIRE';
export const GET_TIMESLOTS = 'GET_TIMESLOTS';
export const GET_HISTORY = 'GET_HISTORY';
export const GET_UPCOMING_CHAT = 'GET_UPCOMING_CHAT';
export const GET_TEXTCHAT_TOKEN = 'GET_TEXTCHAT_TOKEN';
export const GET_DOCUMENT_CATEGORY = 'GET_DOCUMENT_CATEGORY';
export const DELETE_DOCUMENT_CATEGORY = 'DELETE_DOCUMENT_CATEGORY';
export const UPDATE_DOCUMENT_CATEGORY = 'UPDATE_DOCUMENT_CATEGORY';
export const UPDATE_STATUS_DOCUMENT_CATEGORY = 'UPDATE_STATUS_DOCUMENT_CATEGORY';
export const SET_TEXTCHAT_DETAILS = 'SET_TEXTCHAT_DETAILS';
export const SET_VIDEOCHAT_DETAILS = 'SET_VIDEOCHAT_DETAILS';
export const SET_DEMOCHAT_DETAILS = 'SET_DEMOCHAT_DETAILS';
export const SET_SLOT = 'SET_SLOT';
export const GET_APPOPINTMENT_TOPIC = 'GET_APPOPINTMENT_TOPIC';
export const SET_API_ERROR = 'SET_API_ERROR';
export const SET_TWILIO_ERROR = 'SET_TWILIO_ERROR';
export const GET_PDFTemplate = 'GET_PDFTemplate';
export const GET_PDFTemplateDetail = 'GET_PDFTemplateDetail';
export const GET_PDFTemplateAssigment = 'SET_PDFTemplateAssigment';
export const DELETE_PDFTEMPLATE = 'DELETE_PDFTEMPLATE';
export const SWITCHACTIVE_PDFTEMPLATE = 'SWITCHACTIVE_PDFTEMPLATE';
export const SWITCHPUBLIC_PDFTEMPLATE = 'SWITCHPUBLIC_PDFTEMPLATE';
export const SET_CLEAR = 'SET_CLEAR';
export const UNCHECKED_ALL = 'UNCHECKED_ALL';
export const GET_QUESTIONNAIRELIST = 'GET_QUESTIONNAIRELIST';
export const GET_CALLRECORDINGS = 'GET_CALLRECORDINGS';



