import React from "react";
import { useFormikContext } from "formik";
import FormikField from "components/forms/formik-field";
import { PatientProfileContext } from "components/profile/context";
import { uniq } from "lodash";
import { isRequired } from "utils/redux-form";

const AddProduct = () => {
  const { values, setFieldValue } = useFormikContext();

  const { compatibleItems, companyInventory } = React.useContext(
    PatientProfileContext
  );

  const [selectedEqiupmentType, setSelectedEqiupmentType] = React.useState();
  const [selectedManufacturer, setSelectedManufacturer] = React.useState();

  const [selectProductMode, setSelectProductMode] = React.useState("byProduct");
  const equipmentTypes = uniq(companyInventory.map(x => x.type));

  const manufacturerOptions = uniq(
    companyInventory
      .filter(x => !selectedEqiupmentType || x.type == selectedEqiupmentType)
      .map(x => x.manufacturer)
  );

  const compatibleItemIds = compatibleItems.map(x => x.item_id);

  const filteredProducts = companyInventory.filter(
    x =>
      (!selectedEqiupmentType || x.type == selectedEqiupmentType) &&
      (!selectedManufacturer || x.manufacturer == selectedManufacturer) &&
      (!values.compatibleEquipment || compatibleItemIds.includes(x.invItem_ID))
  );

  React.useEffect(() => {
    setFieldValue("product_id", "");
  }, [selectProductMode]);
  return (
    <div>
      <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
        <div>
          <input
            name="radio-item-1"
            value="byProduct"
            type="radio"
            onChange={e => setSelectProductMode(e.target.value)}
            checked={selectProductMode == "byProduct"}
          />
          <label htmlFor="radio-item-1">Select Product</label>
        </div>
        <div>
          <input
            name="radio-item-2"
            value="byId"
            type="radio"
            onChange={e => setSelectProductMode(e.target.value)}
            checked={selectProductMode == "byId"}
          />
          <label htmlFor="radio-item-2">Add Product By Id</label>
        </div>
      </div>
      {selectProductMode == "byId" ? (
        <div>
          <FormikField
            name="product_id"
            label="Product Id*"
            type="text"
            validate={isRequired}
          />
        </div>
      ) : (
        <>
          <div style={{ marginBottom: 10 }}>
            <FormikField
              style={{ display: "flex" }}
              name="compatibleEquipment"
              type="checkbox"
              label="Compatible With Patients Equipment"
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gridGap: 20
            }}
          >
            <div>
              <label> Equipment Type </label>
              <select
                value={selectedEqiupmentType}
                onChange={e => {
                  setSelectedEqiupmentType(e.target.value);
                  setSelectedManufacturer("");
                  setFieldValue("product_id", "");
                }}
              >
                <option />
                {equipmentTypes.map(x => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label> Manufacturer </label>
              <select
                value={selectedManufacturer}
                onChange={e => {
                  setSelectedManufacturer(e.target.value);
                }}
              >
                <option />
                {manufacturerOptions.map(x => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <FormikField
            name="product_id"
            label="Product *"
            component="select"
            disabled={filteredProducts <= 0}
            validate={isRequired}
          >
            <option />
            {filteredProducts.map(({ invItem_ID, description }) => (
              <option key={invItem_ID} value={invItem_ID}>
                {description}
              </option>
            ))}
          </FormikField>
        </>
      )}
    </div>
  );
};

AddProduct.propTypes = {};

export default AddProduct;
