import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader, PureModal, TableButton } from "components/ui";
// import IoIosPaper from "react-icons/lib/io/ios-paper";
import IoMdRefresh from "react-icons/lib/io/refresh";
import IoMdEdit from "react-icons/lib/io/edit";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import InventorySearch from "components/screens/inventory/inventory-table/inventory-search";
import SelectDropdown from "components/forms/select-dropdown";
import { selectors } from "reducers";
import { get, put, post } from "utils/api";
import { useFetch } from "hooks";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import InventoryTableRow from "./table-row";
import useRowSelect, {
  SelectRowContext,
  RowSelectHeaderCell
} from "hooks/useRowSelect";
import HasRolePermission from "components/shared/has-role-permission";
import BulkUpdateCompanyInventoryItems from "./bulk-update-inventory";
import { browserHistory } from "browser-history";

const filterInventoryItem = (searchValue, searchBy) => inventoryItem => {
  if (searchValue.length > 0) {
    switch (searchBy) {
      case "product_name":
        return inventoryItem.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "product_id":
        return inventoryItem.product_id
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "hcpcs":
        return inventoryItem.hcpcs
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      default:
        return false;
    }
  }
  return true;
};
const CompanyInventory = ({
  params: { id: companyId },
  autofit,
  title,
  equipmentTypeOptions,
  manufacturerOptions
}) => {
  // const property = autofit ? "autofit_eligible" : "active";

  const [searchBy, setSearchBy] = useState("product_name");
  const [showSupportedAutofitItems, setShowSupportedAutofitItems] =
    useState(false);
  const [exporting, setExporting] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const getCompanyInventoryAPI = useFetch({
    apiFn: () => get(`companies/${companyId}/inventory`),
    defaultValue: [],
    transformResponse: response => response.inventory
  });

  const updateCompanyInventoryAPI = useFetch({
    apiFn: payload =>
      put(`companies/${companyId}/bulk-update-inventory`, payload),
    onSuccess: () => {
      getCompanyInventoryAPI.fetchData();
    }
  });

  const exportCompanyInventoryAPI = useFetch({
    apiFn: () => post(`companies/${companyId}/export_company_inventory`),
    onSuccess: () => {
      getCompanyInventoryAPI.fetchData();
    }
  });

  useEffect(() => {
    getCompanyInventoryAPI.fetchData();
  }, []);

  const { rows, query, queryDispatcher } = useLocalQuery(
    getCompanyInventoryAPI.response,
    {
      sort: { by: "name", direction: "asc" },
      filters: {}
    }
  );
  const [results, setResults] = useState(rows);
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(results, "xref_id", query);
  const getFilteredInventoryRows = async () => {
    setResults(rows.filter(filterInventoryItem(searchValue, searchBy)));
  };
  useEffect(() => {
    getFilteredInventoryRows();
  }, [rows]);
  useEffect(() => {
    getFilteredInventoryRows();
  }, [searchValue]);

  return (
    <div className="inventory-table-container">
      {title && <PageHeader title={title} />}
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: autofit ? "3fr 1fr" : "1fr",
            columnGap: "10px"
          }}
        >
          <label>
            Search
            <InventorySearch
              searchBy={searchBy}
              setSearchValue={setSearchValue}
              setSearchBy={setSearchBy}
              searchValue={searchValue}
            />
          </label>
          {autofit && (
            <label>
              Only Show Currently Supported Equipment
              <SelectDropdown
                options={[
                  { value: true, text: "Yes" },
                  { value: false, text: "No" }
                ]}
                value={showSupportedAutofitItems}
                onChange={activeValue => {
                  setShowSupportedAutofitItems(activeValue);
                }}
              />
            </label>
          )}
        </div>
        <SelectRowContext.Provider
          value={{
            toggleAllRows,
            toggleRow,
            selectedRows,
            isRowSelected,
            clearSelectedRows
          }}
        >
          <Table
            TableRow={InventoryTableRow}
            containerClass={"query-table inventory-table "}
            columns={[
              {
                header: "all",
                field: "all",
                Cell: RowSelectHeaderCell,
                style: { width: 100 }
              },
              {
                header: "Name",
                field: "name",
                style: { width: "500px" }
              },
              {
                header: "Manufacturer Id",
                field: "product_id"
              },
              {
                header: "Company Id",
                field: "company_product_id"
              },
              {
                header: "HCPCS",
                field: "hcpcs"
              },
              {
                header: "Equipment Type",
                field: "product_type",
                filterKey: "product_type",
                filters: equipmentTypeOptions
              },
              {
                header: "Manufacturer",
                field: "manufacturer",
                filterKey: "manufacturer_id",
                filters: manufacturerOptions
              },
              {
                header: "Avg Reimbursment",
                field: "avgReimbursment"
              },
              {
                header: "Active",
                field: "active",
                filters: [
                  {
                    value: true,
                    text: "Yes"
                  },
                  {
                    value: false,
                    text: "No"
                  }
                ]
              },
              {
                header: "Backordered",
                field: "backordered",
                filters: [
                  {
                    value: true,
                    text: "Yes"
                  },
                  {
                    value: false,
                    text: "No"
                  }
                ]
              },
              {
                header: "Dropship",
                field: "dropship",
                filters: [
                  {
                    value: true,
                    text: "Yes"
                  },
                  {
                    value: false,
                    text: "No"
                  }
                ]
              }
            ]}
            query={query}
            queryDispatcher={queryDispatcher}
            rows={results}
            rowKey="id"
            isFetching={getCompanyInventoryAPI.isFetching}
            controls={
              <div className="query-table-controls">
                <TableButton
                  onClick={() => {
                    getCompanyInventoryAPI.fetchData();
                  }}
                  disabled={getCompanyInventoryAPI.isFetching}
                >
                  <IoMdRefresh />
                </TableButton>
                <HasRolePermission
                  allowedRoles={[
                    "Administrator",
                    "ServiceAdmin",
                    "CompanyAdministrator"
                    //,'ContractorAdministrator'
                  ]}
                >
                  <TableButton
                    title="Export inventory to excel file"
                    disabled={exporting}
                    onClick={async () => {
                      setExporting(true);
                      await exportCompanyInventoryAPI.fetchData();
                      browserHistory.push("/exports");
                    }}
                  >
                    <FaFileExcelO />
                  </TableButton>
                  <PureModal
                    renderTrigger={({ openModal }) => (
                      <TableButton
                        disabled={selectedRows.length === 0}
                        onClick={openModal}
                        title={`Bulk update inventory items (${selectedRows.length} selected)`}
                      >
                        <IoMdEdit />
                      </TableButton>
                    )}
                    renderContent={({ closeModal }) => (
                      <BulkUpdateCompanyInventoryItems
                        inventoryItemIds={selectedRows}
                        isFetching={updateCompanyInventoryAPI.isFetching}
                        updateCompanyInventory={async payload => {
                          await updateCompanyInventoryAPI.fetchData(payload);
                          clearSelectedRows();
                          closeModal();
                          getCompanyInventoryAPI.fetchData();
                        }}
                      />
                    )}
                  />
                </HasRolePermission>
              </div>
            }
          />
        </SelectRowContext.Provider>
      </div>
    </div>
  );
};

export default connect(state => ({
  manufacturerOptions: selectors.getManufacturerOptions(state),
  equipmentTypeOptions: selectors.getEquipmentTypeOptions(state)
}))(CompanyInventory);

CompanyInventory.propTypes = {
  inventoryItems: PropTypes.arrayOf(PropTypes.object),
  isFetchingInventory: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  autofit: PropTypes.bool.isRequired,
  title: PropTypes.string,
  equipmentTypeOptions: PropTypes.array,
  manufacturerOptions: PropTypes.array
};

CompanyInventory.defaultProps = {
  autofit: false
};
