import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import AddEquipment from "components/profile/add-equipment";
import { InlineWaiting } from "components/ui";
import {
  IoAndroidClose,
  IoAndroidCheckboxOutlineBlank,
  IoAndroidCheckboxOutline
} from "react-icons/lib/io";
// import MaskFitting from "./mask-fitting";
import { deletePatientEquipmentItem } from "actions/patient";
//import { HasPermission } from "components/shared/app-permission";
import C from "action-types";
import { formatDate } from "utils/misc";
import * as R from "ramda";

const sortByServiceDate = function(a, b){
 return new Date(b.service_date).getTime() - new Date(a.service_date).getTime()
}
const EquipmentTab = ({
  patientId,
  deletePatientEquipmentItem,
  equipment,
//  autofit_eligible,
  isFetchingEqupment
}) => {
  const [isRemovingEquipment, setIsRemovingEquipment] = React.useState(false);
  async function deletePatientEquipment(equipmentId) {
    try {
      setIsRemovingEquipment(true);
      await deletePatientEquipmentItem({
        patientId,
        equipmentId
      });
    } finally {
      setIsRemovingEquipment(false);
    }
  }

  const groupedByHcpc = R.pipe(
    x =>
      x.map(eq => ({
        ...eq,
        service_date: eq.purchase_date
          ? formatDate(eq.purchase_date)
          : formatDate(eq.created_date)
      })),
    R.groupBy(x => x.HPCPCs),
    R.map(x => R.sort(sortByServiceDate, x)),
    R.values
  )(equipment);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      {/* <HasPermission permission="maskFitting" allowAdmins={false}> */}
      {/*   <MaskFitting */}
      {/*     patientId={patientId} */}
      {/*     autofit_eligible={autofit_eligible} */}
      {/*   /> */}
      {/* </HasPermission> */}
      <table
        style={{
          width: "100%"
        }}
        className="patient-eq-table"
      >
        <thead>
          <tr>
            <th>Type</th>
            <th>Product ID</th>
            <th>Manufacturer</th>
            <th>Style</th>
            <th>Description</th>
            <th>Service Date</th>
            <th>HCPCS</th>
            <th>Autofit Result</th>
          </tr>
        </thead>
        <tbody>
          {equipment.length === 0 && (
            <tr>
              <td colSpan="100%">This patient has no equipment entries yet.</td>
            </tr>
          )}
          {groupedByHcpc.map(hcpc =>
            hcpc.map((eq, index) => (
              <tr
                key={`pe-${index}`}
                className={(hcpc.length > 1 && index > 0) ? "strike-through-old-eq" : ""}
              >
                <td>
                  {isRemovingEquipment || isFetchingEqupment ? (
                    <InlineWaiting />
                  ) : (
                    <span
                      style={{
                        color: "#ff0000",
                        cursor: "pointer",
                        fontSize: "1.5em"
                      }}
                      onClick={() => {
                        deletePatientEquipment(eq.id);
                      }}
                      title="Remove"
                    >
                      <i
                        style={{
                          marginRight: "10px",
                          fontSize: "12px",
                          color: "salmon"
                        }}
                      >
                        <IoAndroidClose />
                      </i>
                    </span>
                  )}
                  {eq.type}
                </td>
                <td>{eq.manufacturerProductID}</td>
                <td>{eq.manufacturer}</td>
                <td>{eq.product_style}</td>
                <td>
                  {eq.description}
                </td>
                <td>{eq.service_date}</td>
                <td>{eq.HPCPCs}</td>
                <td>
                  {eq.autofit_result ? (
                    <span className="autofit-checkbox">
                      <IoAndroidCheckboxOutline color="green" size={20} />
                    </span>
                  ) : (
                    <span className="autofit-checkbox">
                      <IoAndroidCheckboxOutlineBlank color="#ccc" size={20} />
                    </span>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div style={{ padding: "5px 10px" }}>
        <AddEquipment patientId={patientId} />
      </div>
    </div>
  );
};

EquipmentTab.propTypes = {
  patientId: PropTypes.string.isRequired,
  patient: PropTypes.object.isRequired,
  equipment: PropTypes.array.isRequired,
  deletePatientEquipmentItem: PropTypes.func.isRequired,
  autofit_eligible: PropTypes.bool,
  isFetchingEqupment: PropTypes.bool
};

export default connect(
  (state, { patientId }) => ({
    patient: selectors.getPatientProfile(state, patientId),
    equipment: selectors.getPatientsEquipment(state, patientId),
    isFetchingEqupment: state.getIn([
      "fetch",
      C.PATIENT.GET_EQUIPMENT,
      patientId,
      "isFetching"
    ])
  }),
  {
    deletePatientEquipmentItem
  }
)(EquipmentTab);
