import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export const QuantityInput = ({
  line_item_id,
  disabled,
  quantity,
  requestUpdateLineItemById
}) => {
  const [value, setValue] = useState(quantity || 0);
  useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  const handleChange = ({ target: { value } }) => {
    if (!value || value > 0) setValue(value);
  };

  const handleBlur = () => {
    if (value !== quantity) {
      if (value > 9) {
        if (
          window.confirm(
            `Are you sure you want to set quantity to ${value} for this item?`
          )
        )
          requestUpdateLineItemById({
            line_item_id,
            quantity: value
          });
        else {
          setValue(quantity);
        }
      } else
        requestUpdateLineItemById({
          line_item_id,
          quantity: value
        });
    }
  };

  return (
    <input
      className="quantity-input"
      type="number"
      min="1"
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

QuantityInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  quantity: PropTypes.number,
  requestUpdateLineItemById: PropTypes.func.isRequired
};

export const ZeroBilledInput = ({
  value,
  line_item_id,
  requestUpdateLineItemById,
  disabled
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1
    }}
  >
    <input
      type="checkbox"
      checked={!!value}
      disabled={disabled}
      onChange={async ({ target }) => {
        await requestUpdateLineItemById({
          line_item_id,
          zeroChargeItem: target.checked
        });
      }}
    />
  </div>
);

ZeroBilledInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  requestUpdateLineItemById: PropTypes.func.isRequired,
  value: PropTypes.bool
};

export const DropshipInput = ({
  value,
  line_item_id,
  requestUpdateLineItemById,
  disabled
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1
    }}
  >
    <input
      type="checkbox"
      checked={!!value}
      disabled={disabled}
      onChange={async ({ target }) => {
        await requestUpdateLineItemById({
          line_item_id,
          dropship_item: target.checked
        });
      }}
    />
  </div>
);

DropshipInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  requestUpdateLineItemById: PropTypes.func.isRequired,
  value: PropTypes.bool
};

export const ClaimNoteInput = ({
  value,
  line_item_id,
  requestUpdateLineItemById,
  disabled
}) => {
  const [error, setError] = useState();
  const [noteLength, setNoteLength] = useState(value?.length ?? 0);
  const [noteValue, setNoteValue] = useState(value);

  const handleChange = ({ target }) => {
    if (target?.value?.length > 80) {
      setError("Note cannot be longer than 80 characters");
    } else {
      setError("");
    }
    setNoteValue(target?.value);
    setNoteLength(target?.value?.length);
  };

  const submitData = async () => {
    await requestUpdateLineItemById({
      line_item_id,
      claim_order_note: noteValue
    });
  }

  const handleBlur = () => {
    if ((noteValue?.length <= 80) && (value !== noteValue)) submitData();
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="note-validation-error">{error}</span>
      <textarea
        disabled={disabled}
        value={noteValue}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <span>{noteLength}/80</span>
    </div>
)};

ClaimNoteInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  requestUpdateLineItemById: PropTypes.func.isRequired,
  value: PropTypes.string
};