import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting, Tooltip } from "components/ui";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "reducers";
import AddProduct from "./add-product";
import HasRolePermission from "components/shared/has-role-permission";
import IoIosInformationCircleOutline from "react-icons/lib/io/ios-information";
import { useFetch } from "hooks";
import * as msg from "actions/message";
import { post } from "utils/api";
import { getApiErrorMessage } from "utils/misc";
import { today, formatDate } from "utils/dates";

const AddHcpcHistoryRecord = ({ patientId, onSuccess }) => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectors.getUserRole);

  const addPatientHCPCHistoryAPI = useFetch({
    apiFn: values => post(`patients/${patientId}/hcpcs-ordered`, values),
    onSuccess: () => {
      dispatch(msg.message("Patient HCPCP History record added."));
      if (typeof onSuccess == "function") onSuccess();
    },
    onError: err => {
      dispatch(
        msg.errorMessage(
          getApiErrorMessage(err, "Error adding patient HCPC History record")
        )
      );
    }
  });

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      style={{ margin: 30, padding: 5 }}
    >
      <div>
        <Formik
          initialValues={{
            quantity: 1,
            zeroBilled: false,
            service_date: formatDate(today(), "YYYY-MM-DD"),
            compatibleEquipment: true,
            product_id: "",
            replacementItem: true
          }}
          onSubmit={async values => {
            await addPatientHCPCHistoryAPI.fetchData(values);
          }}
          enableReinitialize={true}
        >
          {({ values, isSubmitting, isValid }) => (
            <Form>
              <AddProduct />
              <hr />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20px auto",
                  gridGap: 10
                }}
              >
                <Tooltip
                  id="replacment-item-info"
                  className="helpful-link-tooltip"
                  effect="solid"
                  message="Load replacement item on next order that does not affect patients eligibility. HCPC history record will be removed when said order is accepted or completed."
                >
                  <IoIosInformationCircleOutline size="1rem" />
                </Tooltip>
                <FormikField
                  className="replacment-item-checkbox"
                  style={{ display: "flex" }}
                  name="replacementItem"
                  type="checkbox"
                  disabled={userRole == "SleepCoach"}
                  label={
                    <span style={{ paddingRight: 20 }}>Replacement Item </span>
                  }
                />
              </div>
              <HasRolePermission
                allowedRoles={[
                  "Administrator",
                  "CompanyAdministrator",
                  "OfficeAdministrator",
                  "ServiceCoach",
                  "ServiceAdmin"
                ]}
              >
                <hr />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    alignItems: "center",
                    gridGap: 20
                  }}
                >
                  <FormikField
                    name="quantity"
                    label="Quantity"
                    type="number"
                    disabled={values.replacementItem}
                  />
                  <FormikField
                    name="service_date"
                    label="Order Date"
                    type="date"
                    disabled={values.replacementItem}
                  />
                  <FormikField
                    className="replacment-item-checkbox"
                    style={{ display: "flex" }}
                    name="zeroBilled"
                    type="checkbox"
                    label={
                      <span style={{ paddingRight: 20 }}>Zero Billed</span>
                    }
                    disabled={values.replacementItem}
                  />
                </div>
              </HasRolePermission>
              {isSubmitting ? (
                <InlineWaiting />
              ) : (
                <button
                  className="submit-button"
                  type="submit"
                  disabled={!isValid}
                >
                  Add Item
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

AddHcpcHistoryRecord.propTypes = {
  patientId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  compatibleItems: PropTypes.arrayOf(PropTypes.object)
};

export default AddHcpcHistoryRecord;
