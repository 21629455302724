import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPDFTemplate } from "components/dd_newModules/Redux/Actions/pdftemplate";

import { Link } from "react-router-dom/cjs/react-router-dom";


const formatDate = date => date ? moment(date).format("MM/DD/YYYY") : null;

const AssignedPatients = ({
    PDFTemplates,
    selectedDocumentId
}) => {

    const filtered = PDFTemplates.filter(pdf => pdf.id == selectedDocumentId)
    const selected = filtered[0]

    return (
        <div className='special-fields' style={{ height: "100%" }}>
            <header className="page-header">
                <div className="page-header-title">
                    <h1>
                        <span className="page-title">
                            {`Patients Assigned to ${selected.document_title} document`}
                        </span>
                    </h1>
                </div>
            </header>

            <div style={{ overflowX: "scroll", height: "93%" }}>
                <div
                    style={{
                        display: "grid",
                        gridGap: 10,
                        alignItems: "left",
                        height: "fit-content"
                    }}
                >
                    {selected && (
                        <table className="large-12 ">
                            <thead>
                                <tr>
                                    <th>Document</th>
                                    <th>Patient</th>
                                    <th>Account Number</th>
                                    <th>Company</th>
                                    <th>Status</th>
                                    <th>Sent By</th>
                                    <th>Date Sent</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selected?.patients?.map(p => {
                                    return (
                                        <tr key={p.id}>
                                            <td>{selected.document_title}</td>
                                            <td>
                                                <Link to={`/patients/${p.id}`}>
                                                    {p.name}
                                                </Link>
                                            </td>
                                            <td>{p.account_number}</td>
                                            <td>{p.company}</td>
                                            <td>{p.status == 0 ? "not signed" : "signed"}</td>
                                            <td>{p.sent_by}</td>
                                            <td>{formatDate(selected?.date_of_delivery)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        PDFTemplates: state.getIn(["newRootReducer"]).rdc_PDFTemplate.PDFTemplate,
    }),
    {
        getPDFTemplate,
    }
)(AssignedPatients);

AssignedPatients.propTypes = {
    PDFTemplates: PropTypes.array,
    selectedDocumentId: PropTypes.string,
}