import React from "react";
import { useFetch } from "hooks";
import { PureModal } from "components/ui";
import { path } from "ramda";
import { errorMessage } from "actions/message";
import { USER_FETCH_ACTION_CREATORS, ADMINISTRATOR } from "actions/users";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, getFormOptionsSleepCoachWithUserFirst } from "reducers";
import { aLink } from "utils/styles";
import { get, put } from "utils/api";

const [fetchUsers] = USER_FETCH_ACTION_CREATORS[ADMINISTRATOR];

const ManageTeamLeads = () => {
  const dispatch = useDispatch();
  const admins = useSelector(state => getUsers(state, ADMINISTRATOR));
  const sleepCoaches = useSelector(getFormOptionsSleepCoachWithUserFirst);
  const { response: teamLeads, fetchData: fetchTeamLeads } = useFetch({
    apiFn: () => get("users/team_leads"),
    defaultValue: [],
    transformResponse: teams => (Array.isArray(teams) ? teams : []),
    transformError: path(["response", "body", "status"]),
    clearDataOnFetch: false
  });
  const updateTeamsLead = async (user_guid, team_name) => {
    try {
      await put("users/team_leads", { user_guid, team_name });
      await fetchTeamLeads();
    } catch (err) {
      dispatch(errorMessage("failed to update team lead: " + err.message));
    }
  };
  const updateTLA = async (user_guid, team_name) => {
    try {
      await put("users/tlas", { user_guid, team_name });
      await fetchTeamLeads();
    } catch (err) {
      dispatch(errorMessage("failed to update team lead: " + err.message));
    }
  };
  const updateFollowUpCoach = async (user_guid, old_user_guid, team_name) => {
    try {
      await put("users/follow_up_coach", {
        user_guid,
        team_name,
        old_user_guid
      });
      await fetchTeamLeads();
    } catch (err) {
      dispatch(
        errorMessage("failed to update follow up coach: " + err.message)
      );
    }
  };
  React.useEffect(() => {
    dispatch(fetchUsers());
    fetchTeamLeads();
  }, []);
  return (
    <PureModal
      renderTrigger={({ openModal }) => (
        <div
          style={{
            ...aLink,
            marginLeft: 10
          }}
          onClick={openModal}
        >
          Manage Team Leads
        </div>
      )}
    >
      <div style={{ padding: 10, display: "flex", flex: 1 }}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "16%" }}>Team</th>
              <th style={{ width: "16%" }}>Team Lead</th>
              <th style={{ width: "16%" }}>TLA</th>
              <th style={{ width: "16%" }}>Follow Up Coach 1</th>
              <th style={{ width: "16%" }}>Follow Up Coach 2</th>
              <th style={{ width: "16%" }}>Follow Up Coach 3</th>
            </tr>
          </thead>
          <tbody>
            {teamLeads.map(
              ({ team_name, team_lead, tla, follow_up_coaches }) => (
                <tr key={team_name}>
                  <td>{team_name}</td>

                  <td>
                    <select
                      value={team_lead ?? ""}
                      onChange={async ({ target }) => {
                        await updateTeamsLead(target.value, team_name);
                      }}
                    >
                      <option value="" />
                      {admins.map(({ first_name, last_name, id }) => (
                        <option key={id} value={id}>
                          {first_name} {last_name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={tla ?? ""}
                      onChange={async ({ target }) => {
                        await updateTLA(target.value, team_name);
                      }}
                    >
                      <option value="" />
                      {admins.map(({ first_name, last_name, id }) => (
                        <option key={id} value={id}>
                          {first_name} {last_name}
                        </option>
                      ))}
                    </select>
                  </td>

                  <td>
                    <select
                      value={follow_up_coaches[0] ?? ""}
                      onChange={async ({ target }) => {
                        await updateFollowUpCoach(
                          target.value,
                          follow_up_coaches[0],
                          team_name
                        );
                      }}
                    >
                      <option value="" />
                      {sleepCoaches
                        .filter(x => x.active && x.team_name == team_name)
                        .map(({ GUID, name }) => (
                          <option key={GUID} value={GUID}>
                            {name}
                          </option>
                        ))}
                    </select>
                  </td>

                  <td>
                    <select
                      value={follow_up_coaches[1] ?? ""}
                      onChange={async ({ target }) => {
                        await updateFollowUpCoach(
                          target.value,
                          follow_up_coaches[1],
                          team_name
                        );
                      }}
                    >
                      <option value="" />
                      {sleepCoaches
                        .filter(x => x.active && x.team_name == team_name)
                        .map(({ GUID, name }) => (
                          <option key={GUID} value={GUID}>
                            {name}
                          </option>
                        ))}
                    </select>
                  </td>

                  <td>
                    <select
                      value={follow_up_coaches[2] ?? ""}
                      onChange={async ({ target }) => {
                        await updateFollowUpCoach(
                          target.value,
                          follow_up_coaches[2],
                          team_name
                        );
                      }}
                    >
                      <option value="" />
                      {sleepCoaches
                        .filter(x => x.active && x.team_name == team_name)
                        .map(({ GUID, name }) => (
                          <option key={GUID} value={GUID}>
                            {name}
                          </option>
                        ))}
                    </select>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </PureModal>
  );
};

export default ManageTeamLeads;
