import React, { Component } from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { PageHeader, Waiting } from "components/ui";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCompany, updateCompany, createCompany } from "actions/company";
import { needsUpdate } from "utils/misc";
import CompanyForm from "./company-form";
import { browserHistory } from "browser-history";
import { omit, isEmpty, propOr, pathOr } from "ramda";
import { getRouteParam } from "routes";

export class ManageCompany extends Component {
  componentDidMount() {
    const { companyId, companyProfile, getCompany } = this.props;
    if (
      companyId &&
      needsUpdate(companyProfile.company_name, companyProfile.id, companyId)
    ) {
      getCompany(companyId);
    }
  }

  handleSubmit = async formData => {
    const { updateCompany, createCompany, companyId } = this.props;
    if (companyId) {
      await updateCompany(omit(["orders", "patients", "contacts"], formData));
    } else {
      await createCompany(omit(["orders", "patients", "contacts"], formData));
    }
  };

  render() {
    const {
      companyProfile,
      userRole,
      companyId,
      teamOptions,
      companyOptions,
      patientTypes
    } = this.props;
    const editing = companyId ? true : false;
    if (editing && isEmpty(companyProfile)) return <Waiting />;
    if (
      !editing &&
      isEmpty(patientTypes) &&
      isEmpty(companyOptions) &&
      isEmpty(teamOptions)
    )
      return <Waiting reason="Fetching form options" />;
    return (
      <div>
        <div className="large-12 columns">
          <PageHeader
            title={
              editing ? `Edit ${companyProfile.company_name}` : "New Company"
            }
          />
          <CompanyForm
            teamOptions={teamOptions}
            onSubmit={this.handleSubmit}
            editing={editing}
            userRole={userRole}
            companyId={companyId}
            companyOptions={companyOptions}
            initialValues={
              editing
                ? {
                    ...companyProfile,
                    company_friendly_name: propOr(
                      "",
                      "company_friendly_name",
                      companyProfile
                    ),
                    insurance: {
                      taxonomy_code: pathOr(
                        "",
                        ["insurance", "taxonomy_code"],
                        companyProfile
                      ),
                      npi: pathOr("", ["insurance", "npi"], companyProfile),
                      tax_id: pathOr(
                        "",
                        ["insurance", "tax_id"],
                        companyProfile
                      )
                    },
                    order_form_settings: {
                      bAskOrderUsage: false,
                      bAskSawPhysicianLast: false,
                      bAskUpdatedPhysician: false,
                      bAskWashingSupplies: false,
                      bAskQuantityOnHand: false,
                      bAskHospiceCare: false,
                      bAskPersonPlacingOrders: false,
                      ...(companyProfile?.order_form_settings ?? {})
                    },
                    hasCustomReminderDays: companyProfile?.customReminderDays ?? false, 
                    customReminderDays: companyProfile?.customReminderDays ?? 2 
                  }
                : {
                    company_active: true,
                    order_review: false,
                    phillips_recall_prompt: false,
                    pap_resupply_email_address: "supplies@papresupply.com",
                    reimbursement_multiplier: 1,
                    handles_orders: false,
                    autofill_order_line_items: true,
                    ivr_start: "11:00:00",
                    ivr_end: "18:00:00",
                    insurance: { taxonomy_code: "", npi: "", tax_id: "" },
                    settings: {
                      bContactIn30Mask: false,
                      bContactIn30Seals: false,
                      bContactIn30Chinstrap: false,
                      bContactIn30Filter: false,
                      bContactIn30Headgear: false,
                      bContactIn30Tubing: false,
                      bContactIn30Waterchamber: false,
                      bVariableOutreachManualCall: false,
                      bVariableOutreachIVR: false,
                      bVariableOutreachPatientPortal: false,
                      bRecordsCalls: true,
                      bOptOutYieldsDNC: false,
                      bNotifyDeductibleRenewingJanuary: true,
                      dDeductibleReminder: null,

                      bReadAddressLine2: false,
                      bAllowIVRTransferFromQueue: true
                    },
                    order_form_settings: {
                      bAskOrderUsage: false,
                      bAskSawPhysicianLast: false,
                      bAskUpdatedPhysician: false,
                      bAskWashingSupplies: false,
                      bAskQuantityOnHand: false,
                      bAskHospiceCare: false,
                      bAskPersonPlacingOrders: false,
                      bAllow30DayOrders: true,
                      ...(companyProfile?.order_form_settings ?? {})
                    },
                    patient_type_settings: patientTypes
                  }
            }
            handleCancel={() =>
              editing && companyId
                ? browserHistory.push(`/companies/${companyId}/profile`)
                : browserHistory.push("/companies")
            }
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => ({
      companyId: getRouteParam(props, "id"),
      teamOptions: selectors.getTeamNameOptions(state),
      companyOptions: selectors.getFormOptionsCompany(state),
      companyProfile: selectors.getCompanyProfile(state),
      userRole: selectors.getUserRole(state),
      patientTypes: selectors.getPatientTYpes(state)
    }),
    {
      getCompany,
      updateCompany,
      createCompany
    }
  )(ManageCompany)
);

ManageCompany.propTypes = {
  teamOptions: PropTypes.arrayOf(PropTypes.object),
  companyOptions: PropTypes.arrayOf(PropTypes.object),
  patientTypes: PropTypes.arrayOf(PropTypes.object),
  companyProfile: PropTypes.object,
  getCompany: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  userRole: PropTypes.string
};
