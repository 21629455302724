import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { browserHistory } from "browser-history";
import * as storage from "utils/storage";
import HasRolePermission from "components/shared/has-role-permission";
import { selectors } from "reducers";
import { useSelector } from "react-redux";
import HasAppPermission from "components/shared/has-app-permission";
import IsNotContractedEmployee from "components/shared/is-contracted-employee";
import { FaGoogle, FaNewspaperO, FaAreaChart } from "react-icons/lib/fa";
import {
  MdGroup,
  MdPlaylistAddCheck,
  MdGroupWork,
  MdSettings,
  MdPeople,
  MdLocalHospital
} from "react-icons/lib/md";
import {
  IoIosChatboxes,
  IoIosBriefcase,
  IoPerson,
  IoIosStar,
  IoHeart,
  IoIosPaperplane,
  IoStatsBars,
  IoIosPricetag,
  IoAndroidList,
  IoAndroidCall,
  IoWrench,
  IoHelpCircled,
  IoLogOut,
  IoHammer,
  IoAndroidDocument,
  IoInformatcircled,
  IoClipboard,
  IoIosSpeedometer
} from "react-icons/lib/io";

const NavMenu = ({ requestUserLogout }) => {
  const [expandedNav, setExapandedNav] = React.useState();
  const canMakeCalls = useSelector(selectors.getUserCanMakeCalls);

  function toggleExpanded(name) {
    if (name === expandedNav) setExapandedNav();
    else setExapandedNav(name);
  }

  return (
    <div className="sidebar-menu scrollbar">
      <ul className="navUl side-nav">
        {/* <NavItem */}
        {/*   text="First Time Registration" */}
        {/*   to="/first-time-registration-dashboard" */}
        {/*   allowedRoles={[ */}
        {/*     "Administrator", */}
        {/*     "ServiceAdmin" */}
        {/*   ]} */}
        {/* /> */}
        <HasRolePermission contractorCheck>
          <NavList
            text="Dashboard"
            Icon={IoIosSpeedometer}
            expanded={expandedNav === "Dashboard"}
            toggleList={() => toggleExpanded("Dashboard")}
          >
            <NavItem text="Resupply Dashboard" to="/" />
            <NavItem text="Compliance Dashboard" to="/compliance-dashboard" />
          </NavList>
        </HasRolePermission>
        {canMakeCalls && (
          <>
            <NavList
              text="Outreach"
              Icon={IoAndroidCall}
              expanded={expandedNav === "Outreach"}
              toggleList={() => toggleExpanded("Outreach")}
              allowedRoles={[
                "Administrator",
                "SleepCoach",
                "OfficeAdministrator",
                "CompanyAdministrator",
                "ServiceAdmin",
                "ServiceCoach",
                "Therapist"
              ]}
            >
              <NavItem text="Resupply Patient Outreach" to="/outreach" />
              <NavItem
                text="Compliance Patient Outreach"
                to="/compliance-outreach"
              />
            </NavList>
          </>
        )}
        <NavItem
          text="Team"
          to="/team-dashboard"
          Icon={MdGroup}
          allowedRoles={[
            "Administrator",
            "ServiceAdmin",
            "ContractorAdministrator"
          ]}
        />
        <HasAppPermission permission="orderSupplies">
          <NavItem
            text="IVR Work Table"
            to="/ivr-work"
            Icon={IoHammer}
            allowedRoles={[
              "Administrator",
              // "CompanyAdministrator",
              // "OfficeAdministrator",
              "ServiceCoach",
              "ServiceAdmin",
              "SleepCoach"
            ]}
          />
        </HasAppPermission>

        <NavItem
          text="Score Card"
          to="/score-card"
          Icon={IoIosStar}
          allowedRoles={["ServiceCoach", "SleepCoach"]}
        />
        <HasRolePermission contractorCheck>
          <NavList
            text="Companies"
            Icon={IoIosBriefcase}
            expanded={expandedNav === "Companies"}
            toggleList={() => toggleExpanded("Companies")}
            allowedRoles={[
              "Administrator",
              "CompanyAdministrator",
              "OfficeAdministrator",
              "ServiceCoach",
              "ServiceAdmin",
              "SleepCoach"
            ]}
          >
            <NavItem
              text="All Companies"
              to="/companies"
              allowedRoles={[
                "Administrator",
                "CompanyAdministrator",
                "OfficeAdministrator",
                "ServiceCoach",
                "ServiceAdmin",
                "SleepCoach"
              ]}
            />
            <NavItem
              text="Resupply Contact Logic"
              to="/companies/contact-logic/resupply"
              allowedRoles={["Administrator", "ServiceAdmin"]}
            />
            <NavItem
              text="Compliance Contact Logic"
              to="/companies/contact-logic/compliance"
              allowedRoles={["Administrator", "ServiceAdmin"]}
            />
          </NavList>
        </HasRolePermission>
        <HasRolePermission contractorCheck>
          <NavList
            text="Users"
            Icon={IoPerson}
            expanded={expandedNav === "users"}
            toggleList={() => toggleExpanded("users")}
            allowedRoles={[
              "Administrator",
              "CompanyAdministrator",
              "ServiceAdmin"
            ]}
          >
            <HasAppPermission permission="orderSupplies">
              <NavItem
                text="Create User"
                to="/users/new"
                allowedRoles={["Administrator", "ServiceAdmin"]}
              />
            </HasAppPermission>

            <NavItem
              text="Administrators"
              to="/users/administrators"
              allowedRoles={["Administrator"]}
            />
            <NavItem
              text="Company Administrators"
              to="/users/company-administrators"
            />
            <NavItem
              text="Office Administrators"
              to="/users/office-administrators"
              allowedRoles={["Administrator", "CompanyAdministrator"]}
            />
            <NavItem
              text="Sleep Coaches"
              to="/users/sleep-coaches"
              allowedRoles={["Administrator", "ServiceAdmin"]}
            />
            {/* <NavItem */}
            {/*   text="Compliance Coaches" */}
            {/*   to="/users/compliance-coaches" */}
            {/*   allowedRoles={["Administrator", "ServiceAdmin"]} */}
            {/* /> */}
            <NavItem
              text="Therapists"
              to="/users/therapists"
              allowedRoles={["Administrator"]}
            />
          </NavList>
        </HasRolePermission>

        <HasRolePermission contractorCheck>
          <NavList
            text="Patients"
            Icon={IoHeart}
            expanded={expandedNav === "patients"}
            toggleList={() => toggleExpanded("patients")}
          >
            <NavItem
              text="Create Patient"
              to="/patients/new"
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "ServiceCoach",
                "OfficeAdministrator",
                "CompanyAdministrator"
              ]}
            />
            <NavItem
              text="Bulk Update"
              to="/patients/bulk-update"
              allowedRoles={["Administrator", "ServiceAdmin"]}
            />
            <NavItem
              text="All Patients"
              to={{ pathname: "/patients-table", state: { status: "all" } }}
            />
            <NavItem
              text="Delayed"
              to={{ pathname: "/patients", state: { status: "delayed" } }}
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "SleepCoach",
                "ServiceCoach",
                "CompanyAdministrator",
                "OfficeAdministrator"
              ]}
            />
            <NavItem
              text="Compliance Patients"
              to={{
                pathname: "/patients-compliance",
                state: { status: "all" }
              }}
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "SleepCoach",
                "ServiceCoach",
                "CompanyAdministrator",
                "OfficeAdministrator"
              ]}
            />
            <NavItem
              text="Float Report"
              to={{ pathname: "/patients", state: { status: "float-report" } }}
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "CompanyAdministrator"
              ]}
            />
            <NavItem
              text="Outreach"
              to="/patients/outreach"
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "CompanyAdministrator"
              ]}
            />
            <NavItem
              text="Contacts"
              to={{ pathname: "/patients/contacts" }}
              allowedRoles={["Administrator", "CompanyAdministrator"]}
            />
          </NavList>
        </HasRolePermission>

        <HasAppPermission permission="orderSupplies">
          <NavList
            text="Orders"
            Icon={IoIosBriefcase}
            expanded={expandedNav === "orders"}
            toggleList={() => toggleExpanded("orders")}
          >
            <NavItem
              text="All"
              to={{
                pathname: "/orders-table",
                state: { filters: { status: [] } }
              }}
              allowedRoles={[
                "Administrator",
                "CompanyAdministrator",
                "ServiceAdmin",
                "ServiceCoach",
                "OfficeAdministrator"
              ]}
            />
            <NavItem
              text="New"
              to={{ pathname: "/orders", state: { status: "new" } }}
            />
            <NavItem
              text="Coach Follow Up"
              to={{ pathname: "/orders", state: { status: "follow_up" } }}
            />
            <HasRolePermission contractorCheck>
              <NavItem
                text="Pending Confirmation"
                to={{ pathname: "/orders", state: { status: "pending" } }}
              />
            </HasRolePermission>
            <HasRolePermission contractorCheck>
              <NavItem
                text="Pending Buildout"
                to={{
                  pathname: "/orders",
                  state: { status: "pending_buildout" }
                }}
                allowedRoles={[
                  "Administrator",
                  "CompanyAdministrator",
                  "ServiceAdmin",
                  "ServiceCoach",
                  "OfficeAdministrator",
                  "ContractorAdministrator"
                ]}
              />
            </HasRolePermission>
            <HasRolePermission contractorCheck>
              <NavItem
                text="Internal Processing"
                to={{
                  pathname: "/orders",
                  state: { status: "internal_processing" }
                }}
              />
            </HasRolePermission>
            <HasRolePermission contractorCheck>
              <NavItem
                text="On Hold"
                to={{ pathname: "/orders", state: { status: "on_hold" } }}
              />
            </HasRolePermission>
            <HasRolePermission contractorCheck>
              <NavItem
                text="Accepted"
                to={{ pathname: "/orders", state: { status: "ready" } }}
              />
            </HasRolePermission>
            <HasRolePermission contractorCheck>
              <NavItem
                text="Delayed Orders"
                to={{ pathname: "/orders", state: { status: "delayed" } }}
              />
            </HasRolePermission>
            <HasRolePermission contractorCheck>
              <NavItem
                text="Incorrect"
                to={{ pathname: "/orders", state: { status: "incorrect" } }}
              />
            </HasRolePermission>
            <HasRolePermission contractorCheck>
              <NavItem
                text="Completed"
                to={{ pathname: "/orders", state: { status: "completed" } }}
              />
            </HasRolePermission>
            <HasRolePermission contractorCheck>
              <NavItem
                text="Billed"
                to={{ pathname: "/orders", state: { status: "billed" } }}
              />
            </HasRolePermission>
            <NavItem
              text="Rejected"
              to={{ pathname: "/orders", state: { status: "rejected" } }}
            />
            <NavItem
              text="Voided"
              to={{ pathname: "/orders", state: { status: "voided" } }}
            />
            <NavItem
              text="Ready For Review"
              to={{
                pathname: "/orders",
                state: { status: "ready_for_review" }
              }}
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "ServiceCoach",
                "ContractorAdministrator"
              ]}
            />
            <HasRolePermission contractorCheck>
              <NavItem
                text="Location Assistance Needed"
                to={{
                  pathname: "/orders",
                  state: { status: "location_assistance_needed" }
                }}
              />
            </HasRolePermission>
            <NavItem
              text="Integration Follow Up"
              to={{
                pathname: "/orders",
                state: { status: "integration_follow_up" }
              }}
            />
            <HasRolePermission contractorCheck>
              <NavItem text="Worklist" to="/tasks/orders" />
            </HasRolePermission>
          </NavList>
        </HasAppPermission>
        <IsNotContractedEmployee>
          <NavList
            text="Fulfillment"
            Icon={IoIosPaperplane}
            expanded={expandedNav === "fulfillment"}
            toggleList={() => toggleExpanded("fulfillment")}
          >
            <NavItem
              text="Ready to Ship"
              to={{
                pathname: "/fulfillment",
                state: { status: "ready-to-ship" }
              }}
            />
            <NavItem
              text="Pending Shipment"
              to={{
                pathname: "/fulfillment",
                state: { status: "pending-shipment" }
              }}
            />
            <NavItem
              text="Shipped & Completed"
              to={{
                pathname: "/fulfillment",
                state: { status: "shipped-and-completed" }
              }}
            />
            <NavItem
              text="Backordered"
              to={{
                pathname: "/fulfillment",
                state: { status: "orders-backordered" }
              }}
            />
            <NavItem
              to="/fulfillment/import-tracking"
              text="Import Tracking"
              allowedRoles={["Administrator"]}
            />
          </NavList>
        </IsNotContractedEmployee>

        <HasAppPermission permission="patientTasks">
          <NavList
            text="Tasks"
            Icon={MdPlaylistAddCheck}
            expanded={expandedNav === "tasks"}
            toggleList={() => toggleExpanded("tasks")}
            allowedRoles={[
              "Administrator",
              "SleepCoach",
              "ServiceCoach",
              "ServiceAdmin",
              "OfficeAdministrator",
              "CompanyAdministrator",
              "ContractorAdministrator"
            ]}
          >
            <NavItem to="/tasks/orders" text="Order Tasks" />
            <NavItem to="/tasks/patients" text="Patient Tasks" />
            <NavItem to="/tasks/all" text="All" />
          </NavList>
        </HasAppPermission>
        <HasAppPermission permission="ra_cmn_integration">
          <NavItem
            text="CMNS"
            Icon={MdLocalHospital}
            to="/cmns"
            allowedRoles={[
              "Administrator",
              "SleepCoach",
              "ServiceCoach",
              "ServiceAdmin",
              "OfficeAdministrator",
              "CompanyAdministrator",
              "ContractorAdministrator"
            ]}
          />
        </HasAppPermission>
        <HasRolePermission contractorCheck>
          <NavItem
            to="/insights"
            Icon={IoStatsBars}
            text="Insights"
            allowedRoles={[
              "Administrator",
              "CompanyAdministrator",
              "OfficeAdministrator",
              "ServiceAdmin"
            ]}
          />
        </HasRolePermission>

        <NavItem
          to="/phone-insights"
          Icon={FaAreaChart}
          text="Phone Insights"
          allowedRoles={["Administrator"]}
        />
        <NavItem
          to="/employees"
          Icon={MdPeople}
          text="Employees"
          allowedRoles={[
            "Administrator",
            "ServiceAdmin",
            "ContractorAdministrator"
          ]}
        />
        <NavList
          text="Inventory"
          Icon={IoIosPricetag}
          expanded={expandedNav === "Inventory"}
          toggleList={() => toggleExpanded("Inventory")}
          allowedRoles={[
            "Administrator",
            "SleepCoach",
            "ContractorAdministrator"
          ]}
        >
          <NavItem
            to="/inventory"
            text="Master Inventory"
            allowedRoles={[
              "Administrator",
              "SleepCoach",
              "ContractorAdministrator"
            ]}
          />
          <NavItem
            to="/fulfillment-inventory"
            text="Fulfillment Inventory"
            allowedRoles={["Administrator"]}
          />
          <NavItem
            to="/master-inventory-import"
            text="Inventory Importer"
            allowedRoles={["Administrator"]}
          />
          <NavItem
            to="/fulfillment-inventory-import"
            text="Fulfillment Importer"
            allowedRoles={["Administrator"]}
          />
          <NavItem
            to="/company-inventory-import"
            text="Company Crosswalk Importer"
            allowedRoles={["Administrator"]}
          />
          <NavItem
            to="/crosswalk-inventory-import"
            text="Item Crosswalk Importer"
            allowedRoles={["Administrator"]}
          />
        </NavList>
        <NavItem
          to="/exports"
          Icon={IoAndroidDocument}
          text="Exports"
          allowedRoles={[
            "Administrator",
            "CompanyAdministrator",
            "ServiceAdmin",
            "OfficeAdministrator",
            "ServiceCoach",
            "ContractorAdministrator"
          ]}
        />
        <HasRolePermission contractorCheck>
          <HasAppPermission permission="scheduleChat">
            <NavItem
              text="Video/Text Chats"
              Icon={IoIosChatboxes}
              to="/chats/upcoming"
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "ServiceCoach",
                "CompanyAdministrator",
                "OfficeAdministrator"
              ]}
            />
          </HasAppPermission>
        </HasRolePermission>
        <HasRolePermission contractorCheck>
          <HasAppPermission permission="signatures">
            <NavItem
              to="/pdf-signatures"
              Icon={IoInformatcircled}
              text="PDF Signatures"
              allowedRoles={[
                "Administrator",
                "CompanyAdministrator",
                "OfficeAdministrator",
                "ServiceAdmin",
                "ServiceCoach"
              ]}
            />
          </HasAppPermission>
        </HasRolePermission>
        <HasRolePermission contractorCheck>
          <HasAppPermission permission="questionaires">
            <NavItem
              text="Questionnaires"
              Icon={IoClipboard}
              to="/questionnaires"
              expanded={expandedNav === "Questionnaire"}
              toggleList={() => toggleExpanded("Questionnaire")}
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "ServiceCoach",
                "CompanyAdministrator",
                "OfficeAdministrator"
              ]}
            />
          </HasAppPermission>
        </HasRolePermission>

        <NavList
          text="Articles"
          Icon={FaNewspaperO}
          expanded={expandedNav === "Articles"}
          toggleList={() => toggleExpanded("Articles")}
          allowedRoles={["Administrator" /*"CompanyAdministrator"*/]}
        >
          <NavItem to="/AsNeededArticles" text="As Needed Articles" />
          <NavItem to="/RecurringArticles" text="Recurring Articles" />
        </NavList>
        <NavItem
          to="/DocumentCategory"
          text="Document Categories"
          Icon={IoAndroidList}
          allowedRoles={["Administrator"]}
        />
        <HasRolePermission contractorCheck>
          <NavItem
            to="/phone-book"
            Icon={IoAndroidCall}
            text="Phone Book"
            allowedRoles={["Administrator", "ServiceAdmin", "SleepCoach"]}
          />
        </HasRolePermission>

        <NavItem
          to="/phones/queues"
          Icon={MdGroupWork}
          text="Queues"
          allowedRoles={[
            "Administrator",
            "SleepCoach"
            // "ServiceAdmin",
            // "ServiceCoach"
          ]}
        />
        <NavItem to="/user/profile" Icon={MdSettings} text="My Profile" />
        {/* <HasAppPermission permission="maskFitting"> */}
        {/*   <NavList */}
        {/*     Icon={Icons.Wrench} */}
        {/*     text="Tools" */}
        {/*     expanded={expandedNav === "adminTools"} */}
        {/*     toggleList={() => toggleExpanded("adminTools")} */}
        {/*     allowedRoles={["OfficeAdministrator", "ServiceCoach"]} */}
        {/*   > */}
        {/*     <NavItem */}
        {/*       to="/tools/autofit" */}
        {/*       text="Mask Fittings" */}
        {/*       allowedRoles={["OfficeAdministrator", "ServiceCoach"]} */}
        {/*     /> */}
        {/*   </NavList> */}
        {/* </HasAppPermission> */}
        <NavList
          Icon={IoWrench}
          text="Tools"
          expanded={expandedNav === "adminTools"}
          toggleList={() => toggleExpanded("adminTools")}
          allowedRoles={[
            "Administrator",
            "ServiceAdmin",
            "ServiceCoach",
            "CompanyAdministrator",
            "OfficeAdministrator",
            "Therapist",
            "ContractorAdministrator"
          ]}
        >
          <HasAppPermission permission="maskFitting">
            <NavItem
              to="/tools/autofit"
              text="Mask Fittings"
              allowedRoles={[
                "Administrator",
                "CompanyAdministrator",
                "ServiceAdmin",
                "Therapist"
              ]}
            />
          </HasAppPermission>
          <HasRolePermission contractorCheck>
            <NavItem to="/tracking-table" text="Tracking" />
          </HasRolePermission>
          <NavItem
            to="/tools/patient-import"
            text="Import Patients"
            allowedRoles={["Administrator", "ServiceAdmin", "ServiceCoach"]}
          />
          <NavItem
            to="/tools/insurance-import"
            text="Import Insurances"
            allowedRoles={["Administrator", "ServiceAdmin", "ServiceCoach"]}
          />
          <NavItem
            to="/tools/order-import"
            text="Import Orders"
            allowedRoles={["Administrator"]}
          />
          <NavItem
            to="/tools/backorder-import"
            text="Update Backorders"
            allowedRoles={["Administrator", "ServiceAdmin"]}
          />
          <NavItem
            to="/tools/twilio-export"
            text="Export Twilio"
            allowedRoles={["Administrator"]}
          />
          <NavItem
            to="/tools/ivr-control-center"
            text="IVR Control Center"
            allowedRoles={["Administrator"]}
          />
          <NavItem
            to="/tools/ivr-job-management"
            text="IVR Job Management"
            allowedRoles={["Administrator"]}
          />
          <NavItem
            to="/tools/log-export"
            text="Export Log"
            allowedRoles={[
              "Administrator",
              "CompanyAdministrator",
              "OfficeAdministrator",
              "ServiceAdmin",
              "ContractorAdministrator"
            ]}
          />
          <NavItem
            to="/tools/product-photos"
            text="Product Photos"
            allowedRoles={["Administrator"]}
          />
          <NavItem
            to="/tools/company-inventory"
            text="Company Inventory"
            allowedRoles={["Administrator"]}
          />
        </NavList>
        <NavItem
          to="/support"
          text="Support"
          Icon={IoHelpCircled}
          allowedRoles={["Administrator"]}
        />
        <NavItem
          to="/google-drive"
          text="Drive Files"
          Icon={FaGoogle}
          allowedRoles={["Administrator", "SleepCoach"]}
        />
        <li className="icon-container">
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              requestUserLogout();
              storage.clear();
              browserHistory.push(`/login`);
            }}
            className="menu-link"
          >
            <IoLogOut />
            Logout
          </div>
        </li>
      </ul>
    </div>
  );
};

export default memo(NavMenu);

NavMenu.propTypes = {
  requestUserLogout: PropTypes.func.isRequired
};

const NavItem = memo(({ text, Icon, to, allowedRoles }) =>
  !allowedRoles ? (
    <li className="icon-container">
      <Link to={to}>
        {Icon && <Icon />}
        {text}
      </Link>
    </li>
  ) : (
    <HasRolePermission allowedRoles={allowedRoles}>
      <li className="icon-container">
        <Link to={to}>
          {Icon && <Icon />}
          {text}
        </Link>
      </li>
    </HasRolePermission>
  )
);

NavItem.propTypes = {
  text: PropTypes.string,
  Icon: PropTypes.elementType,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string)
};

const NavList = memo(
  ({ text, Icon, expanded, toggleList, children, allowedRoles }) =>
    !allowedRoles ? (
      <li className="icon-container">
        <div onClick={toggleList} className="menu-link">
          <Icon />
          {text}
        </div>
        {expanded && <ul className="navUl subnav">{children}</ul>}
      </li>
    ) : (
      <HasRolePermission allowedRoles={allowedRoles}>
        <li className="icon-container">
          <div onClick={toggleList} className="menu-link">
            <Icon />
            {text}
          </div>
          {expanded && <ul className="navUl subnav">{children}</ul>}
        </li>
      </HasRolePermission>
    )
);

NavList.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  expanded: PropTypes.bool,
  toggleList: PropTypes.func.isRequired,
  children: PropTypes.node,
  allowedRoles: PropTypes.arrayOf(PropTypes.string)
};
