import { bindActionCreators } from "redux";
import mapValues from "lodash/mapValues";
import * as api from "utils/api";
import { post } from "utils/api";
import { errorMessage, message } from "actions/message";
import { getApiErrorMessage } from "utils/misc";

const PREFIX = "s3/order-profile/";
const prefix = type => PREFIX + type;

const makeApiTypes = name => ({
  [`REQUEST_${name}`]: prefix(`REQUEST_${name}`),
  [`API_${name}`]: prefix(`API_${name}`),
  [`RESPONSE_${name}`]: prefix(`RESPONSE_${name}`),
  [`INVALIDATE_${name}`]: prefix(`INVALIDATE_${name}`)
});

export const Types = {
  REQUEST_ORDER_PROFILE: prefix("REQUEST_ORDER_PROFILE"),
  INVALIDATE_ORDERS: prefix("INVALIDATE_ORDERS"),
  API_PRODUCTS: prefix("API_PRODUCTS"),
  RESPONSE_PRODUCTS: prefix("RESPONSE_PRODUCTS"),
  ...makeApiTypes("ORDER_INFO"),
  ...makeApiTypes("PATIENT_INFO"),
  ...makeApiTypes("COMPLIANCE_INFO"),
  ...makeApiTypes("EQUIPMENT_INFO"),
  ...makeApiTypes("DYSFUNCTION_INFO"),
  ...makeApiTypes("COMPANY_INFO"),
  ...makeApiTypes("LINE_ITEMS"),
  ...makeApiTypes("ACCESS_LOGS"),
  ...makeApiTypes("CHANGE_LOGS"),
  ...makeApiTypes("TYPES"),
  ...makeApiTypes("NOTES"),
  ...makeApiTypes("ORDER_UPDATE"),
  ...makeApiTypes("NOTE_ADD"),
  ...makeApiTypes("LINE_ITEM_REMOVAL"),
  ...makeApiTypes("TRACKING_UPDATE"),
  ...makeApiTypes("NEW_LINE_ITEM_BY_ID"),
  ...makeApiTypes("NEW_LINE_ITEM"),
  ...makeApiTypes("ORDER_PROFILE_ACCESS"),
  ...makeApiTypes("ORDER_HOLD_REASONS"),
  ...makeApiTypes("ADD_ORDER_HOLD_REASON"),
  ...makeApiTypes("UPDATE_ORDER_HOLD_REASON"),
  REQUEST_UPDATE_ORDER_AUTH_INFO: prefix("REQUEST_UPDATE_ORDER_AUTH_INFO"),
  RESPONSE_UPDATE_ORDER_AUTH_INFO: prefix("RESPONSE_UPDATE_ORDER_AUTH_INFO")
};

export const requestOrderProfile = ({ orderId }) => (
  _,
  options = { useCache: false }
) => ({
  type: Types.REQUEST_ORDER_PROFILE,
  orderId,
  useCache: options.useCache
});
export const invalidateOrders = () => ({
  type: Types.INVALIDATE_ORDERS
});

export const requestOrderInfo = ({ orderId }) => () => ({
  type: Types.REQUEST_ORDER_INFO,
  meta: { orderId }
});

export const invalidateOrderInfo = ({ orderId }) => () => ({
  type: Types.INVALIDATE_ORDER_INFO,
  meta: { orderId }
});

export const requestPatientInfo = ({ orderId }) => () => ({
  type: Types.REQUEST_PATIENT_INFO,
  meta: { orderId }
});
export const requestPhysicianInfo = requestPatientInfo;

export const requestComplianceInfo = ({ orderId }) => () => ({
  type: Types.REQUEST_COMPLIANCE_INFO,
  meta: { orderId }
});

export const requestEquipmentInfo = ({ orderId }) => () => ({
  type: Types.REQUEST_EQUIPMENT_INFO,
  meta: { orderId }
});

export const requestDysfunctionInfo = ({ orderId }) => () => ({
  type: Types.REQUEST_DYSFUNCTION_INFO,
  meta: { orderId }
});

export const requestNotes = ({ orderId }) => () => ({
  type: Types.REQUEST_NOTES,
  meta: { orderId }
});

export const invalidateNotes = ({ orderId }) => () => ({
  type: Types.INVALIDATE_NOTES,
  meta: { orderId }
});

export const requestCompanyInfo = ({ orderId }) => () => ({
  type: Types.REQUEST_COMPANY_INFO,
  meta: { orderId }
});

export const requestLineItems = ({ orderId }) => () => ({
  type: Types.REQUEST_LINE_ITEMS,
  meta: { orderId }
});
export const invalidateLineItems = ({ orderId }) => () => ({
  type: Types.INVALIDATE_LINE_ITEMS,
  meta: { orderId }
});

export const requestAccessLogs = ({ orderId }) => () => ({
  type: Types.REQUEST_ACCESS_LOGS,
  meta: { orderId }
});

export const requestChangeLogs = ({ orderId }) => () => ({
  type: Types.REQUEST_CHANGE_LOGS,
  meta: { orderId }
});

export const requestTypes = () => () => ({
  type: Types.REQUEST_TYPES
});

export const requestNoteAdd = ({ orderId }) => ({ note }) => ({
  type: Types.REQUEST_NOTE_ADD,
  payload: { note },
  meta: { orderId }
});

export const requestUpdateOrder = ({ orderId }) => (updates = {}) => ({
  type: Types.REQUEST_ORDER_UPDATE,
  payload: { update: { ...updates } },
  meta: { orderId }
});

export const requestStatusUpdate = ({ orderId }) => (
  status,
  rejected_reason = null
) => ({
  type: Types.REQUEST_ORDER_UPDATE,
  payload: { update: { status, rejected_reason } },
  meta: { orderId }
});

export const requestIncorrectShipmentUpdate = ({ orderId }) => (
  incorrect_shipment
) /* boolean */ => ({
  type: Types.REQUEST_ORDER_UPDATE,
  payload: { update: { incorrect_shipment } },
  meta: { orderId }
});

export const requestFulfillmentUpdate = ({ orderId }) => fulfillment_type => ({
  type: Types.REQUEST_ORDER_UPDATE,
  payload: { update: { fulfillment_type } },
  meta: { orderId }
});

export const requestSalesOrderNumberUpdate = ({
  orderId
}) => sales_order_number => ({
  type: Types.REQUEST_ORDER_UPDATE,
  payload: { update: { sales_order_number } },
  meta: { orderId }
});

export const requestLineItemRemoval = ({ orderId }) => lineItemId => ({
  type: Types.REQUEST_LINE_ITEM_REMOVAL,
  payload: { lineItemId },
  meta: { orderId }
});

export const requestTrackingUpdate = ({
  orderId,
  lineItemIds,
  ship_date,
  carrier,
  tracking_number
}) => ({
  type: Types.REQUEST_TRACKING_UPDATE,
  payload: {
    update: {
      ship_date,
      carrier,
      tracking_number
    }
  },
  meta: { orderId, lineItemIds }
});

export const requestNewLineItemById = ({ orderId }) => (
  product_id,
  addQuantity
) => ({
  type: Types.REQUEST_NEW_LINE_ITEM_BY_ID,
  payload: {
    create: {
      product_id,
      addQuantity
    }
  },
  meta: { orderId }
});
export const requestUpdateLineItemById = ({ orderId }) => ({
  line_item_id,
  quantity,
  zeroChargeItem,
  dropship_item,
  claim_order_note,
  isUpdate = true
}) => ({
  type: Types.REQUEST_NEW_LINE_ITEM_BY_ID,
  payload: {
    create: {
      line_item_id,
      quantity,
      zeroChargeItem,
      dropship_item,
      claim_order_note
    }
  },
  meta: { orderId, isUpdate }
});

export const updateLineItemById = (orderId, line_item_id, updates) => ({
  type: Types.REQUEST_NEW_LINE_ITEM_BY_ID,
  payload: {
    create: {
      line_item_id,
      ...updates
    }
  },
  meta: { orderId, isUpdate: true }
});

export const requestNewLineItem = ({ orderId }) => (id, addQuantity) => ({
  type: Types.REQUEST_NEW_LINE_ITEM,
  payload: {
    create: {
      id,
      addQuantity
    }
  },
  meta: { orderId }
});

export const requestOrderProfileAccess = ({ orderId }) => () => ({
  type: Types.REQUEST_ORDER_PROFILE_ACCESS,
  meta: { orderId }
});

export const requestOrderHoldReasons = orderId => async dispatch => {
  try {
    dispatch({
      type: Types.REQUEST_ORDER_HOLD_REASONS,
      meta: { orderId }
    });
    const response = await api.get(`orders/${orderId}/order_hold_reasons`);
    dispatch({
      type: Types.RESPONSE_ORDER_HOLD_REASONS,
      meta: { orderId },
      payload: response
    });
  } catch (error) {
    dispatch({
      type: Types.INVALIDATE_ORDER_HOLD_REASONS,
      meta: {
        orderId
      },
      error: error
    });
    dispatch(
      errorMessage("Something went wrong: could not get order hold reasons")
    );
  }
};

export const updateOrderHoldReason = (
  orderId,
  { holdReasonPK, resolved, comments = "" }
) => async dispatch => {
  try {
    dispatch({
      type: Types.REQUEST_UPDATE_ORDER_HOLD_REASON,
      payload: {
        holdReasonID: holdReasonPK,
        resolved,
        comments
      },
      meta: { orderId }
    });
    await api.put(`orders/${orderId}/order_hold_reason`, {
      holdReasonID: holdReasonPK,
      resolved,
      comments
    });
    dispatch({
      type: Types.RESPONSE_UPDATE_ORDER_HOLD_REASON,
      payload: {
        holdReasonID: holdReasonPK,
        resolved,
        comments
      },
      meta: { orderId }
    });
    await requestOrderHoldReasons(orderId)(dispatch);
  } catch (error) {
    dispatch(errorMessage(getApiErrorMessage(error)));
  }
};

export const addOrderHoldReason = (
  orderId,
  holdReasonId,
  comments
) => async dispatch => {
  try {
    dispatch({
      type: Types.REQUEST_ADD_ORDER_HOLD_REASON,
      meta: { orderId }
    });
    await api.post(`orders/${orderId}/order_hold_reason`, {
      holdReasonId,
      comments
    });
    dispatch({
      type: Types.RESPONSE_ADD_ORDER_HOLD_REASON,
      payload: { holdReasonId, comments }
    });
    dispatch(message("Order Worklist Item Added"));
    requestOrderHoldReasons(orderId)(dispatch);
  } catch (error) {
    dispatch(errorMessage(getApiErrorMessage(error)));
  }
};

export const updateOrderAuthInfo = (orderId, authInfo) => async dispatch => {
  try {
    dispatch({
      type: Types.REQUEST_UPDATE_ORDER_AUTH_INFO,
      payload: authInfo,
      meta: { orderId }
    });
    const { result } = await api.post(`orders/${orderId}/auth_info`, authInfo);
    dispatch({
      type: Types.RESPONSE_UPDATE_ORDER_AUTH_INFO,
      payload: result,
      meta: { orderId }
    });
    dispatch(invalidateLineItems({ orderId })());
  } catch (error) {
    dispatch(errorMessage(getApiErrorMessage(error)));
    dispatch(invalidateLineItems({ orderId })());
  }
};

export const invalidateOrderHoldReasons = ({ orderId }) => () => ({
  type: Types.INVALIDATE_ORDER_HOLD_REASONS,
  meta: { orderId }
});

export const initiateSendOrderToNikoHealth = (requestorMGUID, orderMGUID) => async (dispatch)  => {
  try {
    const response = await post(`Niko/OrderExport/${requestorMGUID}/${orderMGUID}`);
    if (response === 201) {
      dispatch(message("Order Exported Successfully"));
    } else {
      dispatch(errorMessage(response));
    }
  } catch (error) {
    dispatch(errorMessage(`Order export failed, ${error}`));
  } finally {
    dispatch(invalidateOrderInfo({ orderId: orderMGUID })());
  }
}

export const initiateRetrieveOrderFromNikoHealth = (requestorMGUID, orderMGUID) => async (dispatch)  => {
  try {
    const response = await post(`Niko/OrderImport/${requestorMGUID}/${orderMGUID}`);
    if (response === 200) {
      dispatch(message("Order Updated Successfully"));
    } else {
      dispatch(errorMessage(response));
    }
  } catch (error) {
    dispatch(errorMessage(`Order update failed, ${error}`));
  } finally {
    dispatch(invalidateLineItems({ orderId: orderMGUID })());
    dispatch(invalidateOrderInfo({ orderId: orderMGUID })());
  }
}

export const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    mapValues(
      {
        requestOrderInfo,
        invalidateOrderInfo,
        requestPatientInfo,
        requestPhysicianInfo,
        requestComplianceInfo,
        requestEquipmentInfo,
        requestDysfunctionInfo,
        requestNotes,
        invalidateNotes,
        requestCompanyInfo,
        requestLineItems,
        invalidateLineItems,
        requestAccessLogs,
        requestChangeLogs,
        requestTypes,
        requestNoteAdd,
        requestStatusUpdate,
        requestUpdateOrder,
        requestIncorrectShipmentUpdate,
        requestFulfillmentUpdate,
        requestSalesOrderNumberUpdate,
        requestLineItemRemoval,
        requestNewLineItemById,
        requestNewLineItem,
        requestUpdateLineItemById,
        requestOrderProfileAccess,
        requestOrderProfile
      },
      fn => fn(props)
    ),
    dispatch
  );
