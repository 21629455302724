import React, { useState } from "react";
import PropTypes from "prop-types";
import { Rows, InlineWaiting } from "components/ui";
import { dissoc, assoc } from "ramda";
import IoMdTrash from "react-icons/lib/io/trash-b";
import { selectors } from "reducers";
import { connect } from "react-redux";

const BulkUpdateInventoryItems = ({
  inventoryItemIds,
  updateCompanyInventory,
  isFetching,
  company
}) => {
  const [fields, setFields] = useState({});
  return (
    <div style={{ padding: 5 }}>
      <h3>Inventory Bulk Update ({inventoryItemIds.length})</h3>
      <div style={{ padding: 10 }}>
        <Rows>
          {Object.keys(fields).map(field => (
            <div
              key={field}
              className="variable-field"
              style={{
                display: "flex",
                alignItems: "flex-start"
              }}
            >
              <div
                style={{
                  flex: "1",
                  marginRight: "2rem",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <div style={{ fontWeight: "bold" }}>{field}</div>
                <input
                  type="checkbox"
                  checked={fields[field]}
                  onChange={({ target }) => {
                    setFields(assoc(field, target.checked));
                  }}
                  style={{ margin: "unset" }}
                />
              </div>
              <i
                onClick={() => setFields(dissoc(field))}
                title="Remove field"
                style={{
                  fontSize: "2rem",
                  cursor: "pointer"
                }}
              >
                <IoMdTrash />
              </i>
            </div>
          ))}
        </Rows>
        <div className="add-field-container">
          <select
            onChange={({ target }) => {
              setFields(assoc(target.value, false));
            }}
          >
            <option value="" className="choose-field">
              Choose Field
            </option>
            {!("active" in fields) && <option value="active">active</option>}
            {!("backordered" in fields) && (
              <option value="backordered">backordered</option>
            )}
            {!("dropship" in fields) && (
              <option value="dropship">dropship</option>
            )}
          </select>
        </div>
        {isFetching ? (
          <InlineWaiting />
        ) : (
          <button
            disabled={Object.keys(fields).length <= 0}
            onClick={async () => {
              await updateCompanyInventory({
                ...fields,
                company_inventory_item_guids: inventoryItemIds
              });
            }}
          >
            Submit
          </button>
        )}
        {company.company_relationships?.children.length > 0 ? (
          <div>
            You will also be editing items for:
            <ul>
              {company.company_relationships.children.map(x => (
                <li key={x.company_guid}>{x.company_name}</li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

BulkUpdateInventoryItems.propTypes = {
  inventoryItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateCompanyInventory: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  company: PropTypes.object
};

export default connect(state => ({
  company: selectors.getCompanyProfile(state)
}))(BulkUpdateInventoryItems);
