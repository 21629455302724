import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MdCheck from "react-icons/lib/md/check";
import MdClear from "react-icons/lib/md/clear";
// import { HasPermission } from "components/shared/app-permission";

const CompanyTableRow = ({
  visibleColumns,
  id,
  company_name,
  patients,
  orders,
  contacts = {},
  walk_in_orders,
  active,
  team_name,
  isS3User,
  manages_compliance
}) => (
  <tr>
    <td>
      <Link to={`/companies/${id}/profile`}>{company_name}</Link>
    </td>
    {isS3User && visibleColumns["Team Name"] && <td>{team_name}</td>}
    {visibleColumns["Company Active"] && (
      <td>
        {active ? (
          <MdCheck color="green" size={16} />
        ) : (
          <MdClear color="red" size={16} />
        )}
      </td>
    )}
    {visibleColumns["Walk In Orders"] && <td>{walk_in_orders}</td>}
    {visibleColumns["Total Orders"] && <td>{orders.count}</td>}
    {visibleColumns["Patient Insights"] && (
      <React.Fragment>
        <td className="patient-insights-column">{patients.active}</td>
        <td>{patients.on_hold}</td>
        <td>{patients.inactive}</td>
        <td>{patients.eligible}</td>
      </React.Fragment>
    )}
    {visibleColumns["Order Statuses"] && (
      <React.Fragment>
        <td className="order-insights-column">{orders.new_count}</td>
        <td>{orders.ready_for_review_count}</td>
        <td>{orders.pending_buildout_count}</td>
        <td>{orders.pending_count}</td>
        <td>{orders.coach_followup_count}</td>
        <td>{orders.on_hold_count}</td>
        <td>{orders.location_assistance_needed_count}</td>
        <td>{orders.sent_to_fulfillment_count}</td>
        <td>{orders.pending_shipment_count}</td>
        <td>{orders.backordered_count}</td>
        <td>{orders.accepted_count}</td>
        <td>{orders.completed_count}</td>
        <td>{orders.rejected_count}</td>
        <td>{orders.incorrect_count}</td>
        <td>{orders.internal_processing_count}</td>
      </React.Fragment>
    )}
    {visibleColumns["Contact Insights"] && (
      <React.Fragment>
        <td className="contact-insights-column">
          {contacts.Total_Success_Outbound_calls}
        </td>
        <td>{contacts.Total_Success_Inbound_call}</td>
        <td>{contacts.Total_Success_Email_Resupply_Request}</td>
        <td>{contacts.Total_Success_Text_messages}</td>
        <td>{contacts.Total_Success_Push_Notifications}</td>
      </React.Fragment>
    )}
    {visibleColumns["Manages Compliance"] && (
      <td>
        {manages_compliance ? (
          <MdCheck color="green" size={16} />
        ) : (
          <MdClear color="red" size={16} />
        )}
      </td>
    )}
  </tr>
);

export default CompanyTableRow;

CompanyTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  company_name: PropTypes.string.isRequired,
  patients: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  contacts: PropTypes.object,
  walk_in_orders: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  team_name: PropTypes.string.isRequired,
  visibleColumns: PropTypes.shape({
    "Total Orders": PropTypes.bool,
    "Walk In Orders": PropTypes.bool,
    "Team Name": PropTypes.bool,
    "Patient Insights": PropTypes.bool,
    "Company Active": PropTypes.bool,
    "Order Statuses": PropTypes.bool,
    "Contact Insights": PropTypes.bool,
    "Manages Compliance": PropTypes.bool
  }),
  isS3User: PropTypes.bool.isRequired,
  manages_compliance: PropTypes.bool
};
