import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InlineWaiting, PureModal } from "components/ui";
import PatientSearchForm from "components/patient-search-form";
import { addPatientToTemplate } from "components/dd_newModules/Redux/Actions/pdftemplate";
import ConfirmationModal from "components/shared/confirmation-modal";
import { selectors } from "reducers";
import { PHP_Domain } from "environment";

const AssignPdfs = ({ PDFTemplates, addPatientToTemplate, user, assignments }) => {
  const [selectedPatients, setSelectedPatients] = React.useState([]);
  const [selectedPdfTemplate, setSelectedPdfTemplate] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [askForSignature, setAskForSignature] = React.useState(false);
  
  const submit = async () => {
    setIsLoading(true);
    await addPatientToTemplate(
      selectedPdfTemplate,
      selectedPatients
    );
    setIsLoading(false);
    setSelectedPatients([]);
  }

  useEffect(() => {
    if (assignments && assignments.length > 0 && askForSignature) {
      document.getElementById("coachSignatureModal").click();
    }
  }, [assignments])

  function openPDFWindow() {
    document.body.style.pointerEvents = "none";
    document.body.style.cursor = "wait";
    document.body.style.filter = "brightness(80%)";
    const encoded_token = window.btoa(user.authentication_token);
    const htmlTemplateId = PDFTemplates.find(x => x.id === selectedPdfTemplate).html_template_id;
    var token = JSON.parse(atob(user.authentication_token.replace('Bearer ','').split('.')[1])).token;
    
    const route = assignments.length > 1 ?
      `${PHP_Domain}/signature?coach=true&token=${token}&template_id=${htmlTemplateId}&signature_id=${assignments[0]}&bulk=${assignments.toString()}` 
      : 
      `${PHP_Domain}/signature?coach=true&token=${token}&template_id=${htmlTemplateId}&signature_id=${assignments[0]}`
    var new_window = window.open(route, "_blank", "height=" + screen.height + ", width=" + screen.width);
    var timer = setInterval(() => {
      if (new_window.closed) {
        clearInterval(timer);
        onUnload();
      }
    }, 1000);
    setAskForSignature(false)
  }
  
  function onUnload() {
    document.body.style.pointerEvents = "inherit";
    document.body.style.cursor = "default";
    document.body.style.filter = "brightness(100%)";
  }

  return (
    <div style={{ display: "grid", gridTemplateRows: "auto 1fr auto" }}>
      <PatientSearchForm
        selectedPatients={selectedPatients}
        setSelectedPatients={setSelectedPatients}
        feature="signatures"
      />
      <div style={{ marginTop: 20 }}>
        <h4>Select PDF Signature Template</h4>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            gridGap: 20
          }}
        >
          <select
            value={selectedPdfTemplate}
            onChange={({ target }) => {
              setSelectedPdfTemplate(target.value);
            }}
          >
            <option />
            {PDFTemplates.filter((template)=>template.active).map(({ id, document_title }) => (
              <option value={id} key={id}>
                {document_title}
              </option>
            ))}
          </select>
          {isLoading ? (
            <InlineWaiting />
          ) : (
            <button
              disabled={selectedPatients.length == 0 || !selectedPdfTemplate}
              onClick={async () => { setAskForSignature(true); await submit(); }}
            >
              Assign Patients
            </button>
          )}

          <PureModal
            renderTrigger={({ openModal }) => <div onClick={() => openModal()} id="coachSignatureModal"></div>}
            renderContent={({ closeModal }) =>
              <ConfirmationModal
                title="Would you like to sign the template before sending it out to the patient?"
                closeModal={closeModal}
                onlyShowOneButton={false}
                noOptionText={"No"}
                notNowAction={closeModal}
                yesAction={openPDFWindow}
              />
            }
          >
          </PureModal>
        </div>
      </div>
    </div>
  );
};

AssignPdfs.propTypes = {
  PDFTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPatientToTemplate: PropTypes.func.isRequired
};

export default connect(
  state => ({
    PDFTemplates: state.getIn(["newRootReducer"]).rdc_PDFTemplate.PDFTemplate,
    user: selectors.getUser(state),
    assignments: state.getIn(["newRootReducer"]).rdc_PDFTemplate.PDFTemplateAssignment
  }),
  { addPatientToTemplate }
)(AssignPdfs);
