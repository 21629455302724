import {
  GET_PDFTemplate,
  GET_PDFTemplateDetail,
  DELETE_PDFTEMPLATE,
  SWITCHACTIVE_PDFTEMPLATE,
  SWITCHPUBLIC_PDFTEMPLATE,
  SET_CLEAR,
  UNCHECKED_ALL,
  GET_PDFTemplateAssigment
} from "../ActionTypes";

const initialState = {
  PDFTemplate: [],
  PDFTemplateDetail: {},
  IsCleared: false,
  IsUnchecked: false
};

const reducerPDFTemplate = (state = initialState, action) => {
  switch (action.type) {
    case GET_PDFTemplate:
      return {
        ...state,
        PDFTemplate: action.payload
      };
    case GET_PDFTemplateDetail: {
      const pdfDetails = state.PDFTemplate.find(m => m.id === action.payload);
      return {
        ...state,
        PDFTemplateDetail: pdfDetails
      };
    }
    case DELETE_PDFTEMPLATE: {
      const filteredCollection = state.PDFTemplate.filter(
        m => m.id !== action.payload
      );
      return {
        ...state,
        PDFTemplate: filteredCollection
      };
    }
    case SWITCHACTIVE_PDFTEMPLATE: {
      const updatedCollection = state.PDFTemplate.map(m =>
        m.id === action.payload.tid
          ? { ...m, active: action.payload.active }
          : m
      );
      return {
        ...state,
        PDFTemplate: updatedCollection
      };
    }
    case SWITCHPUBLIC_PDFTEMPLATE: {
      const updatedCollection = state.PDFTemplate.map(m =>
        m.id === action.payload.tid
          ? { ...m, is_shared_document: action.payload.isPublic }
          : m
      );
      return {
        ...state,
        PDFTemplate: updatedCollection
      };
    }
    case SET_CLEAR:
      return {
        ...state,
        IsCleared: action.payload
      };
    case UNCHECKED_ALL:
      return {
        ...state,
        IsUnchecked: action.payload
      };
    case GET_PDFTemplateAssigment:
      return {
        ...state,
        PDFTemplateAssignment: action.payload
      };
    default:
      return state;
  }
};

export default reducerPDFTemplate;
