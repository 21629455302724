import React from "react";
import PropTypes from "prop-types";
import { ProfileCard } from "components/ui/index";
import CompanyProfileContacts from "./company-profile-contacts";
import { path } from "ramda";
import { HasPermission } from "components/shared/app-permission";
import HasRolePermission from "components/shared/has-role-permission";
import { ListItem } from "components/ui";
import { Link } from "react-router-dom";


const CompanyOverview = ({ company }) => (
  <div
    style={{
      display: "grid",
      gridGap: "30px",
      gridTemplateColumns: company.company_relationships.parent_guid 
        ? "1fr 1fr 1fr 1fr" 
        : "1fr 1fr 1fr"
    }}
  >
    <HasRolePermission contractorCheck={true}>

    <HasPermission permission="orderSupplies">
      <ProfileCard
        title="Orders"
        items={{
          Total: path(["orders", "count"], company),
          Accepted: path(["orders", "accepted_count"], company),
          "Back Ordered": path(["orders", "backordered_count"], company),
          "Coach Follow Up": path(["orders", "coach_followup_count"], company),
          Completed: path(["orders", "completed_count"], company),
          Incorrect: path(["orders", "incorrect_count"], company),
          "Internal Processing": path(
            ["orders", "internal_processing_count"],
            company
            ),
            "Location Assistance": path(
              ["orders", "location_assistance_needed_count"],
              company
              ),
              New: path(["orders", "new_count"], company),
              "On Hold": path(["orders", "on_hold_count"], company),
              Pending: path(["orders", "pending_count"], company),
              "Pending Buildout": path(
                ["orders", "pending_buildout_count"],
                company
                ),
                "Pending Shipment": path(
                  ["orders", "pending_shipment_count"],
            company
            ),
            "Ready For Review": path(
              ["orders", "ready_for_review_count"],
              company
              ),
              Rejected: path(["orders", "rejected_count"], company),
              "Sent to Fulfillment": path(
                ["orders", "sent_to_fulfillment_count"],
            company
          )
        }}
        />
    </HasPermission>
    <CompanyProfileContacts />
    <ProfileCard
      title="Patients"
      items={{
        Active: path(["patients", "active"], company),
        Inactive: path(["patients", "inactive"], company),
        Eligible: path(["patients", "eligible"], company),
      }}
      />
      {company.company_relationships.parent_guid && 
        <div className="company-profile-administrator" style={{ marginBottom: "1rem" }}>
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid rgb(238, 238, 238)"
            }}
          >
            Company Info
          </h4>
          <div className="profile-administrator-container">
            <ListItem
              label="Parent Company"
              value={
                <Link to={`/companies/${company.company_relationships.parent_guid}/profile`}>
                  {company.company_relationships.parent_name}
                </Link>}
              style={{ padding: 0 }}
            />
            {company.company_relationships.children.length > 0 && 
              <div>
                <ListItem
                  label="Children:"
                  value={<span></span>}
                  className="small-list-item"
                  style={{ padding: 0, fontSize: "1rem" }}
                  underline
                />
                <div>
                  <ul style={{ paddingLeft: 10, fontSize: "1rem" }}>
                    {company.company_relationships.children.map((item, index) => (
                      <li key={index}>
                        <Link to={`/companies/${item.company_guid}/profile`}>
                          {item.company_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            }
          </div>
        </div>
      
      }
      
    </HasRolePermission>
  </div>
);

CompanyOverview.propTypes = { company: PropTypes.object.isRequired };

export default CompanyOverview;
